import { ELanguage } from '~/constants/enum'
import { Locales } from '~/locales/i18n-types'

export const getTimeRead = (data: number, locale: Locales) => {
  if (locale === ELanguage.En) {
    return `${data} min read`
  } else {
    return `${data} phút đọc`
  }
}

export function fromNow(date, locale: Locales) {
  console.log(date)
  const now = new Date()
  const createdAt = new Date(date)

  const seconds = Math.floor((now.getTime() - createdAt.getTime()) / 1000)
  const intervals = [
    { label: { en: 'year', vi: 'năm' }, seconds: 31536000 },
    { label: { en: 'month', vi: 'tháng' }, seconds: 2592000 },
    { label: { en: 'week', vi: 'tuần' }, seconds: 604800 },
    { label: { en: 'day', vi: 'ngày' }, seconds: 86400 },
    { label: { en: 'hour', vi: 'giờ' }, seconds: 3600 },
    { label: { en: 'minute', vi: 'phút' }, seconds: 60 },
    { label: { en: 'second', vi: 'giây' }, seconds: 1 },
  ]

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i]
    const count = Math.floor(seconds / interval.seconds)
    if (count !== 0) {
      const label = interval.label[locale]
      return count === 1
        ? locale === ELanguage.En
          ? `${count} ${label} ago`
          : `${count} ${label} trước`
        : locale === ELanguage.En
          ? `${count} ${label}s ago`
          : `${count} ${label} trước`
    }
  }
  return locale === ELanguage.En ? 'just now' : 'vừa xong'
}

export const formatDobDate = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
